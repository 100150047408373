import {Injectable} from '@angular/core';
import 'rxjs/add/operator/toPromise';

import {SelectItem, TreeNode} from 'primeng/api';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/catch';
import {Default} from '../__helpers/entities/Default';
import {Column} from '../__helpers/entities/Column';
import {UserData} from '../__helpers/entities/UserData';
import {GlobalSettings} from 'GlobalSettings';
import {PageContent} from '../__helpers/entities/PageContent';
import {MenuElements} from '../__helpers/entities/MenuElement';
import {DataGenerateTable} from '../__helpers/entities/DataGenerateTable';
import {ReportSaved} from '../__helpers/entities/ReportSaved';
import {Campaign} from '../__helpers/entities/Campaign';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {Observable} from 'rxjs/Observable';
import {TargetingGroup} from '../__helpers/entities/TargetingGroup';
import {GlobalTargeting} from '../__helpers/entities/GlobalTargeting';
import {TranslateService} from '@ngx-translate/core';
import {ResponseInfo} from 'app/__helpers/entities/ResponseInfo';
import {Constants} from '../__helpers/entities/Constants';
import {AdSpaceRequest} from '../__helpers/entities/AdSpaceRequest';
import {MediaGallery} from '../__helpers/entities/MediaGallery';
import {Media} from '../__helpers/entities/Media';
import {TemplateGroup} from '../__helpers/entities/TemplateGroup';
import {InventoryFilter} from '../__helpers/entities/filters/InventoryFilter';
import {Period} from '../__helpers/entities/filters/Period';
import {MediaFilter} from '../__helpers/entities/filters/MediaFilter';
import {UserFilter} from '../__helpers/entities/filters/UserFilter';
import {Filter} from '../__helpers/entities/Filter';
import {UtilsFun} from 'app/__helpers/entities/UtilsFun';
import {NameExcluded} from '../__helpers/entities/NameExcluded';
import {ColumnWithType} from '../__helpers/entities/ColumnWithType';
import {NotificationData} from '../__helpers/entities/NotificationData';
import {Preset} from '../__helpers/entities/Preset';
import {GeoTag} from '../__helpers/entities/GeoTag';
import {BLCampaignEntryData} from '../__helpers/entities/BLCampaignEntryData';
import {HttpClient, HttpParams} from '@angular/common/http';
import {JwtPermissions} from '../__helpers/jwtPermissions.service';
import { FormatInfo } from 'app/_reportsMVC/_helper/FormatInfo';
import { CurrencyHelper } from 'app/__helpers/entities/CurrencyHelper';
import { PublisherIssue } from 'app/_cerberusMVC/cerberusPublisher/entity/PublisherIssue';
import {TableService} from './table.service';
import {Subject, throwError, BehaviorSubject} from 'rxjs';
import {PubConstant} from '../_publisherMVC/_helpers/PubConstant';
import {UserAllElement} from '../__helpers/entities/UserAllElement';
import {UserAs} from '../__helpers/UserAs';
import {SelectItemType} from "../__helpers/entities/SelectItemType";
import {GroupConfigMembers} from "../__helpers/entities/GroupConfigMembers";
import {ReportResponse} from "../__helpers/entities/ReportResponse";
import {EntityResponseDTO} from '../_adminMVC/gpidManagement/model/EntityResponseDTO';
import {AppConfigService} from "../__helpers/appConfig/appConfig.service";
import {AppConfigEnum} from "../__helpers/appConfig/appConfigEnum";
import {Language} from '../__helpers/entities/language.class';
import {Algorithm} from '../__helpers/entities/algorithm.class';
import {GlobalEvent} from '../__helpers/entities/globalEvent.class';
import {Format} from '../__helpers/entities/format.class';
import {IspProvider} from '../__helpers/entities/ispProvider.class';
import {RepoConstants} from "../_reportsMVC/_helper/RepoConstants";
import {Country} from '../__helpers/entities/country.class';

@Injectable({
  providedIn: 'root',
})
export class InfoService {

  stopReport = false;

  myReport: DataGenerateTable;

  expectedReport: number;

  changingLang = false;

// Filtri
  user?: UserData;
  error = '';

  cols: Column[]; // Columns Report Campaign
  cols2: Column[]; // Columns Report Revenue
  cols3: Column[]; // Columns Report Audience Extension

  allUsers: UserAllElement[] = []; // all User loaded if admin

  // Observable async condition
  public _userGetted: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public _elementsGetted: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public _conditionChanges: ReplaySubject<any> = new ReplaySubject<any>();
  public _allUsersGotten: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  bodyExpanded = false;

  // REST calls' URLs

  private readonly getElementsURL = GlobalSettings.APIurl + '/api/getElements';
  private readonly getPermissionsURL = GlobalSettings.APIurl + '/api/v1/user/${userID}/permissions';
  private readonly setTimezoneURL = GlobalSettings.APIurl + '/api/v1/user/timezone';
  private readonly showMembersURL = GlobalSettings.APIurl + '/api/v1/user/group';
  private readonly getDomainsURL = GlobalSettings.APIurl + '/api/getDomains';
  private readonly getAppsURL = GlobalSettings.APIurl + '/api/getApp';
  private readonly getDomainsByRootsURL = GlobalSettings.APIurl + '/api/getDomainsByRoots';
  private readonly getDomainsNamesURL = GlobalSettings.APIurl + '/api/getDomainsNames';
  private readonly getDomainsNamesByIdURL = GlobalSettings.APIurl + '/api/v1/publisher/domain';
  private readonly getAppsNamesURL = GlobalSettings.APIurl + '/api/getAppNames';
  private readonly domainsListURL = GlobalSettings.APIurl + '/api/domainsList';
  private readonly getCampaignTargetingURL = GlobalSettings.APIurl + '/api/getCampaignTargeting';
  private readonly brandsListURL = GlobalSettings.APIurl + '/api/brandsList';
  private readonly appsListURL = GlobalSettings.APIurl + '/api/appList';
  private readonly getBrandsNamesURL = GlobalSettings.APIurl + '/api/getBrandsNames';
  private readonly getPublishersURL = GlobalSettings.APIurl + '/api/getPublishers';
  private readonly getTargetingTemplatesURL = GlobalSettings.APIurl + '/api/getTemplates';
  private readonly getIntegrationMethodURL = GlobalSettings.APIurl + '/api/v1/publisher/referrer/integration/method';
  private readonly getKeywordSourcesListURL = GlobalSettings.APIurl + '/api/getKeywordSourcesList';
  private readonly getIspListURL = GlobalSettings.APIV2url + '/v2/isp-providers';
  private readonly getDmpCategoryURL = GlobalSettings.APIurl + '/api/getDmpCategory';
  private readonly getInventoryCategoriesURL = GlobalSettings.APIurl + '/api/getInventoryCategories';
  private readonly getInventoryCategoriesListURL = GlobalSettings.APIurl + '/api/getInventoryCategoriesList';
  private readonly getIabCategoriesURL = GlobalSettings.APIurl + '/api/getIABCategories';
  private readonly getEntityListURL = GlobalSettings.APIurl + '/api/getEntityList';
  private readonly getUsersByEntityURL = GlobalSettings.APIurl + '/api/v1/publisher';
  private readonly getCuratorsURL = GlobalSettings.APIurl + '/api/v1/publisher/audience-extension';
  private readonly getEntitiesOnTypeURL = GlobalSettings.APIurl + '/api/getEntitiesOnType';
  private readonly getAdvertisersURL = GlobalSettings.APIurl + '/api/getAdvertisers';
  private readonly getSegmentsURL = GlobalSettings.APIurl + '/api/getSegments';
  private readonly getOldStrategyURL = GlobalSettings.APIurl + '/api/getOldStrategy';
  private readonly getCampaignsURL = GlobalSettings.APIurl + '/api/getCampaigns';
  private readonly getCreativeGroupsURL = GlobalSettings.APIurl + '/api/getCreativeGroups';
  private readonly getAdsURL = GlobalSettings.APIurl + '/api/getAds';
  private readonly getStrategyURL = GlobalSettings.APIurl + '/api/getStrategies';
  private readonly getMediaURL = GlobalSettings.APIurl + '/api/getMedia';
  private readonly getBrandsURL = GlobalSettings.APIurl + '/api/getBrands';
  private readonly getFormatsURL = GlobalSettings.APIV2url + '/v2/formats';
  private readonly getLanguagesURL = GlobalSettings.APIV2url + '/v2/languages';
  private readonly getClusterUserURL = GlobalSettings.APIurl + '/api/getClusterUser';
  private readonly getCountriesURL = GlobalSettings.APIurl + '/api/getCountries';
  private readonly getCountriesV2URL = GlobalSettings.APIV2url + '/v2/countries';
  private readonly getRegionsURL = GlobalSettings.APIurl + '/api/getRegions';
  private readonly getCitiesURL = GlobalSettings.APIurl + '/api/getCities';
  private readonly getUserURL = GlobalSettings.APIurl + '/api/v1/user/iu';
  private readonly getGlobalTargetingsURL = GlobalSettings.APIurl + '/api/getGlobalTargetings';
  private readonly getPublisherTargetingsURL = GlobalSettings.APIurl + '/api/getPublisherTargetings';
  private readonly addGlobalTargetingsURL = GlobalSettings.APIurl + '/api/addGlobalTargetings';
  private readonly addPublisherTargetingsURL = GlobalSettings.APIurl + '/api/addPublisherTargetings';
  private readonly updateDomainCampaignURL = GlobalSettings.APIurl + '/api/updateDomainCampaign';

  private readonly getCreativesURL =  GlobalSettings.APIurl + '/api/getCreatives';
  private readonly addCreativeURL =  GlobalSettings.APIurl + '/api/addCreative';
  private readonly modifyCreativeURL =  GlobalSettings.APIurl + '/api/modifyCreative';
  private readonly deleteCreativeURL =  GlobalSettings.APIurl + '/api/deleteCreative';
  private readonly disjoinCreativeURL =  GlobalSettings.APIurl + '/api/disjoinCreative';
  private readonly joinCreativesURL =  GlobalSettings.APIurl + '/api/joinCreatives';

  private readonly getNotificationsURL =  GlobalSettings.APIurl + '/api/getNotifications';
  private readonly getCampaignsBlacklistURL =  GlobalSettings.APIurl + '/api/getCampaignsBlacklist';
  private readonly restoreCampaignNotificationURL =  GlobalSettings.APIurl + '/api/restoreCampaignNotification';
  private readonly setReadNotificationsURL =  GlobalSettings.APIurl + '/api/setReadNotifications';
  private readonly addCampaignToBlacklistURL =  GlobalSettings.APIurl + '/api/addCampaignToBlacklist';
  private readonly getAlgorithmsURL = GlobalSettings.APIV2url + '/v2/algorithms';
  private readonly getAgeRangesURL = GlobalSettings.APIurl + '/api/getAgeRanges';
  private readonly getCampaignsListURL = GlobalSettings.APIurl + '/api/getCampaignsList';
  private readonly setCampaignStatusURL = GlobalSettings.APIurl + '/api/adUnitStatus';
  private readonly getCampaignDetailURL = GlobalSettings.APIurl + '/api/getCampaignDetail';
  private readonly getContractURL = GlobalSettings.APIurl + '/api/getContract';
  private readonly getTermsURL = GlobalSettings.APIurl + '/api/getTerms';
  public  readonly getReportURL = GlobalSettings.APIurl + '/api/report';
  public  readonly getReportURLV2 = GlobalSettings.APIV2url + '/v2/report/retro-compatible/enqueue';
  public  readonly getReportTestURLV2 = GlobalSettings.APIV2url + '/v2/report/retro-compatible/direct-report';
  public  readonly getReportResponseURL = GlobalSettings.APIV2url + '/v2/report/generated/';
  private readonly stopReportURL = GlobalSettings.APIurl + '/api/stopReport';
  private readonly getSaveReportURL = GlobalSettings.APIurl + '/api/saveReport';
  private readonly getSavedReportsURL = GlobalSettings.APIurl + '/api/getSavedReports';
  private readonly getSavedReportURL = GlobalSettings.APIurl + '/api/showSavedReport';
  private readonly startAutoReportURL = GlobalSettings.APIurl + '/api/report/startAutoReport';
  private readonly stopAutoReportURL = GlobalSettings.APIurl + '/api/report/stopAutoReport';
  private readonly deleteAutoReportURL = GlobalSettings.APIurl + '/api/report/deleteSavedReport';
  private readonly reportLinkGenerationURL = GlobalSettings.APIurl + '/api/report/reportLinkGeneration';
  private readonly reportLinkDeleteURL = GlobalSettings.APIurl + '/api/report/reportLinkDelete';
  private readonly getColsListURL = GlobalSettings.APIurl + '/api/getColsList';
  private readonly changeEmailURL = GlobalSettings.APIurl + '/api/changeEmail';
  private readonly getBookableEventsURL = GlobalSettings.APIV2url + '/v2/columns/events/bookable';
  private readonly getAllUsersURL = GlobalSettings.APIurl + '/api/getAllUsers';
  private readonly modifyGroupNameURL = GlobalSettings.APIurl + '/api/manager/modifyGroupName';
  private readonly modifyGroupTypeURL = GlobalSettings.APIurl + '/api/manager/modifyGroupType';

  private readonly getUserGroupsURL = GlobalSettings.APIurl + '/api/manager/getUserGroups';
  private readonly getAllGroupsURL = GlobalSettings.APIurl + '/api/manager/getAllGroups';
  private readonly changeConfigUserURL = GlobalSettings.APIurl + '/api/manager/changeConfigUser';
  private readonly changeConfigGroupURL = GlobalSettings.APIurl + '/api/manager/changeConfigGroup';
  private readonly changeGroupUserURL = GlobalSettings.APIurl + '/api/manager/changeGroupUser';
  private readonly getUserConfigURL = GlobalSettings.APIurl + '/api/manager/getUserConfig';
  private readonly getGroupConfigURL = GlobalSettings.APIurl + '/api/manager/getGroupConfig';
  private readonly getIdentifyURL = GlobalSettings.APIurl + '/api/getIdentify';
  private readonly switchUserURL =  GlobalSettings.APIurl + '/api/v1/user/switch';
  private readonly deleteUserURL =  GlobalSettings.APIurl + '/api/manager/deleteUser';
  private readonly createGroupURL =  GlobalSettings.APIurl + '/api/manager/createGroup';
  private readonly saveAdminDefaultColumnsURL =  GlobalSettings.APIurl + '/api/manager/saveAdminDefaultColumns';
  private readonly validateAppsURL =  GlobalSettings.APIurl + '/api/v1/ctv/validate';
  private readonly validateCapURL =  GlobalSettings.APIurl + '/api/zipCodes';
  private readonly gamingViewURL =  GlobalSettings.APIurl + '/api/v1/igaming';

  private readonly browsersURL =  GlobalSettings.APIurl + '/api/getBrowsers';
  private readonly osURL =  GlobalSettings.APIurl + '/api/getOs';
  private readonly devicesURL =  GlobalSettings.APIurl + '/api/getDevices';

  private readonly getAllEntititesURL = GlobalSettings.APIV2url + '/v2/entities';
  private readonly getAllAuthoritiesURL = GlobalSettings.APIV2url + '/v2/admin/authorities/all';
  private readonly updateGroupAuthoritiesURL = GlobalSettings.APIV2url + '/v2/groups';
  private readonly getGroupAuthoritiesURL = GlobalSettings.APIV2url + '/v2/groups';
  private readonly updateUserAuthoritiesURL = GlobalSettings.APIV2url + '/v2/users';
  private readonly getUserAuthoritiesURL = GlobalSettings.APIV2url + '/v2/users';


  // Issues
  private readonly issueURL = GlobalSettings.APIurl + '/api/v1/publisher/issue';

  // Extra Api
  private readonly getTimezonesUnAuthURL = GlobalSettings.APIurl + '/v1/global/timezone';

  // Invite Notification
  private readonly getInviteNotificationsURL = GlobalSettings.APIurl + '/api/v1/user/invite/notification';

  private changingUser = new BehaviorSubject(undefined);
  currentUserChange = this.changingUser.asObservable();

  elements: PageContent;
  itemsHome: MenuElements = {link: Constants.LINK_HOME, label: 'Dashboard', icon: 'fa-home'} as MenuElements;

  static getColumnId(col: Column): string {
    if (col) {
      return col.id  + ',' +  col.type;
    }
    return '';
  }

  static getLanguage(language: string): number {
    switch (language) {
      case 'it': {
        return 0;
      }
    }
    return 1;
  }

  constructor(public translate: TranslateService, private http: HttpClient, private appConfig: AppConfigService) {

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    this.translate.addLangs(['en', 'it']);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');

    this.clearReport();
  }

  clearReport() {
    this.myReport = new DataGenerateTable();
    this.myReport.filters = new Filter();
    this.myReport.filters.period = new Period();
    this.myReport.filters.inventoryFilter = new InventoryFilter();
    this.myReport.filters.mediaFilter = new MediaFilter();
    this.myReport.filters.userFilter = new UserFilter();
  }

  isPublisher(): boolean {
    return (this.user && (this.user.type === Constants.PUBLISHER));
  }

  isBuyer(): boolean {
    return (this.user && (this.user.type === Constants.BUYER));
  }

  isReferrer(): boolean {
    return (this.user && (this.user.type === Constants.REFERRER));
  }

  /**
   * Utenti publisher con privilegi in reportistica
   */
  isSpecialPublisher(): boolean {
    return (this.user && (this.user.entityId === Constants.ITALIA_ONLINE_ENTITY || this.user.entityId === Constants.ZIFF_MEDIA_ENTITY_ID));
  }

  private isPound(): boolean {
    return (this.user && (this.user.type === Constants.BUYER) && (this.user.self === 1) && (this.user.country === 82));
  }

  getCurrency(): string {
    return (this.isPound()) ? 'fal fa-pound-sign' : 'fal fa-euro-sign';
  }

  isPagePermission(myLinkString: string): boolean {
    return (myLinkString && this.elements && this.elements.items && this.elements.items.length > 0 &&
      this.elements.items.some(elem => elem.subItems && elem.subItems.some(el => el.link && el.link.split('/').pop() === myLinkString)));
  }

  isPagePermissionPlus(myLinkString: string): boolean {
    return (myLinkString && this.elements && this.elements.items && this.elements.items.length > 0 &&
      this.elements.items.some(elem => elem.subItems && elem.subItems.some(el => el.link && el.link.indexOf(myLinkString) >= 0)));
  }

  isSegmentFilterPermission(): boolean {
    return this.elements && this.elements.campaign_report && this.elements.campaign_report.filters &&
      this.elements.campaign_report.filters.length > 0 && this.elements.campaign_report.filters.some(elem => elem.id === 3 &&
        elem.columns && elem.columns.length > 0 && elem.columns.some(el => el.id === 8));
  }

  isCampaignFilterPermission(): boolean {
    return this.elements && this.elements.campaign_report && this.elements.campaign_report.filters &&
      this.elements.campaign_report.filters.length > 0 && this.elements.campaign_report.filters.some(elem => elem.id === 3 &&
        elem.columns && elem.columns.length > 0 && elem.columns.some(el => el.id === 2));
  }

  isStrategyFilterPermission(): boolean {
    return this.elements && this.elements.campaign_report && this.elements.campaign_report.filters &&
      this.elements.campaign_report.filters.length > 0 && this.elements.campaign_report.filters.some(elem => elem.id === 3 &&
        elem.columns && elem.columns.length > 0 && elem.columns.some(el => el.id === 3));
  }

  // Assegna l'oggetto user
  userIniOperations(myUser: UserData) {
    this.user = myUser;
    FormatInfo.myLocale = myUser.locale;
    CurrencyHelper.loadCurrency(this.user.currency);
  }

  populateField(res: DataGenerateTable, type: number) {
    // groupBy
    this.myReport = res;
    // column
    const cols: Column[] = res.cols;
    switch (type) {
      case Constants.REPORT_CAMPAIGN_ID: case Constants.REPORT_SEGMENT_ID: {
        for (let i = 0; i < cols.length; i++) {
          for (const infoCol of this.cols) {
            if (cols[i].id === infoCol.id && cols[i].type === infoCol.type) {
              cols[i].label = infoCol.label;
            }
          }
        }
        break;
      }
      case Constants.REPORT_REVENUE_ID: case Constants.REPORT_PAGE_ID: {
        for (let i = 0; i < cols.length; i++) {
          for (const infoCol of this.cols2) {
            if (cols[i].id === infoCol.id && cols[i].type === infoCol.type) {
              cols[i].label = infoCol.label;
            }
          }
        }
        break;
      }
      case Constants.REPORT_AUDIENCE_EXTENSION_ID: {
        for (let i = 0; i < cols.length; i++) {
          for (const infoCol of this.cols3) {
            if (cols[i].id === infoCol.id && cols[i].type === infoCol.type) {
              cols[i].label = infoCol.label;
            }
          }
        }
        break;
      }
    }
    this.myReport.cols = cols;
  }

  elementLoader(res: PageContent) {
    this.cols = [];
    if (res.campaign_report && res.campaign_report.panelColumn) {
      for (const pan of res.campaign_report.panelColumn) {
        if (pan.columns) {
          for (const col of pan.columns) {
            const myCol: Column = UtilsFun.cloneField(col);
            myCol.header = pan.header;
            this.cols.push(myCol);
          }
        }
      }
    }
    this.cols2 = [];
    if (res.revenue_report && res.revenue_report.panelColumn) {
      for (const pan of res.revenue_report.panelColumn) {
        if (pan.columns) {
          for (const col of pan.columns) {
            const myCol: Column = UtilsFun.cloneField(col);
            myCol.header = pan.header;
            this.cols2.push(myCol);
          }
        }
      }
    }
    this.cols3 = [];
    if (res.audience_extension_report && res.audience_extension_report.panelColumn) {
      for (const pan of res.audience_extension_report.panelColumn) {
        if (pan.columns) {
          for (const col of pan.columns) {
            const myCol: Column = UtilsFun.cloneField(col);
            myCol.header = pan.header;
            this.cols3.push(myCol);
          }
        }
      }
    }
    if (!res.hbAdsTxt) { res.hbAdsTxt = []}
    if (!res.suiteAdsTxt) { res.suiteAdsTxt = []}
    PubConstant.DEFAULT_ADSTXT = [...res.hbAdsTxt, ...res.suiteAdsTxt].join('\n');
    PubConstant.HB_ADSTXT = [...res.hbAdsTxt].join('\n');
  }

  // Restituisce la colonna a partire dall'ID. Necessario selezionare la fonte del set di colonne e eventualmente se è una metrica o un evento
  getColFromId(colId: number, type: 'campaign'|'revenue'|'audienceExtension', metric_event?: string): Column {
    if (type === 'campaign') {
      return this.cols.find(elem => !metric_event ? elem.id === colId : (elem.id === colId && elem.type === metric_event));
    }
    if (type === 'revenue') {
      return this.cols2.find(elem => !metric_event ? elem.id === colId : (elem.id === colId && elem.type === metric_event));
    }
    if (type === 'audienceExtension') {
      return this.cols3.find(elem => !metric_event ? elem.id === colId : (elem.id === colId && elem.type === metric_event));
    }
  }

  /**
   * Shows the sidebar in large screen
   */
  compress() {
    this.bodyExpanded = false;
    const divs: HTMLCollectionOf<Element> = document.getElementsByClassName('main_content');
    if (divs) {
      for (let i = 0; i < divs.length; i++) {
        (divs.item(i) as HTMLElement).style.marginLeft = '';
      }
    }

    const div:  HTMLElement = document.getElementById('navbar');
    if (div) {
          div.style.marginLeft = '260px';
      }
  }

  /**
   * Hides the sidebar in large screen
   */
  expand() {
    this.bodyExpanded = true;
    const divs:  HTMLCollectionOf<Element> = document.getElementsByClassName('main_content');
    if (divs) {
      for (let i = 0; i < divs.length; i++) {
        if (divs.item(i)) {
          (divs.item(i) as HTMLElement).style.marginLeft = '10px';
        }
      }
    }
    const div:  HTMLElement = document.getElementById('navbar');
    if (div) {
      div.style.marginLeft = '0px';
    }
  }

  clearFields() {
    this.clearReport();
    this.putDefaults();
  }

  putDefaults() {
    this.myReport.filters.period.id = Default.periodo;
    this.myReport.groupBy = Default.groupBy;
  }

  /**
   *
   * @param typeReport (earning, campaign...)
   * @param queryReport (clickhouse, athena...)
   * @param isQueryString (true if the report return only the query string)
   *
   */
  generateTable(typeReport: number, queryReport?: number, isQueryString?: boolean): Observable<any> {
    const selected  = this.extractReportQuery();
    if (queryReport) {
      selected.new_report = queryReport;
    } else {
      delete selected.new_report;
    }
    return this.getReport(selected, typeReport, isQueryString);
  }

  // create the query for the report
  extractReportQuery(): DataGenerateTable {
    if (!this.myReport.timezone) {
      this.myReport.timezone = this.user.timezone;
    }
    UtilsFun.cleanObjProprerty(this.myReport.filters.period);
    UtilsFun.cleanObjProprerty(this.myReport.filters.inventoryFilter);
    UtilsFun.cleanObjProprerty(this.myReport.filters.mediaFilter);
    UtilsFun.cleanObjProprerty(this.myReport.filters.userFilter);
    const tempReport: DataGenerateTable = UtilsFun.cloneField(this.myReport);
    if (tempReport.cols && tempReport.cols.length > 0) {
      tempReport.cols = tempReport.cols.map(col => ({id: col.id, type: col.type}));
    }
    return tempReport;
  }

  logUserIn() {
    this._userGetted.next( true);
  }

  logElementIn() {
    this._elementsGetted.next( true);
  }

  logAllUsersIn() {
    this._allUsersGotten.next(true);
  }

  conditionChanges() {
    this._conditionChanges.next( true);
  }

  getUserGettedInObs(): Observable<boolean> {
    return this._userGetted.asObservable();
  }

  getElementsGettedInObs(): Observable<boolean> {
    return this._elementsGetted.asObservable();
  }

  getConditionChangesInObs(): Observable<boolean> {
    return this._conditionChanges.asObservable();
  }

  getAllUsersInObs(): Observable<boolean> {
    return this._allUsersGotten.asObservable();
  }

/******************************************************* API *********************************************************/

  getElements(language: number): Observable<PageContent> {
    if (language === undefined) {language = 1; }
    const params = {lang: language.toString()};
    return this.http.get<PageContent>(this.getElementsURL, {params: params}).retry(3);
  }

  setTimezone(timezone: number, userId?: number): Observable<any> {
    const params: any = {};
    if (userId !== undefined) {params['idUser'] = userId.toString(); }
    return this.http.put<any>(this.setTimezoneURL, {timezone: timezone}, {params: params});
  }

  getGroupMembers(groupSelected: number): Observable<GroupConfigMembers[]> {
    return this.http.get<GroupConfigMembers[]>(this.showMembersURL + '/' + groupSelected);
  }

  getPermissions(userId: number): Observable<string[]> {
    if (UserAs.userAsID) { userId = UserAs.userAsID}
    return this.http.get<string[]>(this.getPermissionsURL.replace('${userID}', userId + ''));
  }

  // restituisce lista di tutti gli utenti se sei admin
  getAllUsers(onlyUsername?: boolean): Observable <UserAllElement[]> {
    const params: any = {};
    if (onlyUsername) {params['onlyUsername'] = onlyUsername; }
    return this.http.get<UserAllElement[]>(this.getAllUsersURL, {params: params});
  }

  // restituisce lista di tutti i gruppi relativi alla tipologia di entity dell'utente selezionato
  getUserGroups(idUtente: number): Observable <SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getUserGroupsURL + '/' + idUtente);
  }

  // restituisce lista di tutti i gruppi se sei admin
  getAllGroups(type?: number): Observable <SelectItemType[]> {
    const params: any = {};
    if (type) {params['type'] = type; }
    return this.http.get<SelectItemType[]>(this.getAllGroupsURL, {params: params});
  }

  // restituisce il json configurazione dell'utente selezionato
  getUserConfig(id: string): Observable<PageContent> {
    const params = {id: id, lang: this.user.language.toString()};
    return this.http.get<PageContent>(this.getUserConfigURL, {params: params});
  }

  // restituisce il json configurazione del gruppo selezionato
  getGroupConfig(id: string): Observable <PageContent> {
    const params = {id: id, lang: this.user.language.toString()};
    return this.http.get<PageContent>(this.getGroupConfigURL, {params: params});
  }

  editGroupName(idGroup: number, newName: string): Observable<any> {
    return this.http.put<any>(this.modifyGroupNameURL, {id: idGroup, name: newName});
  }

  saveGroupType(idGroup: number, newType: number): Observable<any> {
    return this.http.put<any>(this.modifyGroupTypeURL, {id: idGroup, type: newType});
  }

  getColsList(type?: string, idHeader?: number): Observable<Column[]> {
    const params: any = {};
    if (type) {params['type'] = type; }
    if (idHeader) {params['group'] = idHeader.toString(); }
    return this.http.get<Column[]>(this.getColsListURL, {params: params});
  }

  // restituisce il ResponseInfo dell'utente che si vuole impersonare
  getIdentify(lang: number, id?: number, isAllPermission?: number): Observable <ResponseInfo> {
    const params: any = {};
    if (id) {params['id'] = id.toString(); }
    if (isAllPermission) {params['admin'] = isAllPermission.toString(); }
    if (lang) {params['lang'] = lang.toString(); }
    return this.http.get<ResponseInfo>(this.getIdentifyURL, {params: params});
  }

  switchUser(id: number): Observable <ResponseInfo> {
    const params: any = {};
    if (id) {params['id'] = id.toString(); }
    return this.http.get<ResponseInfo>(this.switchUserURL, {params: params});
  }

// Filtri Inventory
  getDomains(term?: string): Observable<SelectItem[]> {
    const params: any = {};
    if (term) { params['key'] = term; }
    return this.http.get<SelectItem[]>(this.getDomainsURL, {params: params});
  }

  getApps(term?: string): Observable<SelectItem[]> {
    const params: any = {};
    if (term) { params['key'] = term; }
    return this.http.get<SelectItem[]>(this.getAppsURL, {params: params});
  }

  getDomainsByRoots(domains?: number[]): Observable<SelectItem[]> {
    return this.http.post<SelectItem[]>(this.getDomainsByRootsURL, {domains: domains});
  }

  getDomainsNames(ids?: number[]): Observable<SelectItem[]> {
    const params: any = {};
    if (ids && ids.length > 0) { params['ids'] = ids.toString(); }
    return this.http.get<SelectItem[]>(this.getDomainsNamesURL, {params: params});
  }

  getDomainsNamesById(ids?: number[]): Observable<SelectItem[]> {
    return this.http.post<SelectItem[]>(this.getDomainsNamesByIdURL, ids);
  }

  getAppsNames(ids?: number[]): Observable<SelectItem[]> {
    const params: any = {};
    if (ids && ids.length > 0) { params['ids'] = ids.toString(); }
    return this.http.get<SelectItem[]>(this.getAppsNamesURL, {params: params});
  }

  getMultipleApps(list: string[]): Observable<SelectItem[]> {
    return this.http.post<SelectItem[]>(this.appsListURL, {array: list});
  }

  getMultipleDomains(list: string[], store?: boolean): Observable<SelectItem[]> {
    const params: any = {};
    if (store) {params['store'] = 1; }
    return this.http.post<SelectItem[]>(this.domainsListURL, {array: list}, {params: params});
  }

  getCampaignTargeting(value: string, rule: string): Observable<NameExcluded[]> {
    const params: any = {};
    if (value) { params['value'] = value; }
    if (rule) { params['rule'] = rule; }
    return this.http.get<NameExcluded[]>(this.getCampaignTargetingURL, {params: params});
  }

  getBrandsNames(ids?: number[]): Observable<SelectItem[]> {
    const params: any = {};
    if (ids && ids.length > 0) { params['ids'] = ids.toString(); }
    return this.http.get<SelectItem[]>(this.getBrandsNamesURL, {params: params});
  }

  getMultipleBrands(list: string[]): Observable<SelectItem[]> {
    return this.http.post<SelectItem[]>(this.brandsListURL, {array: list});
  }

  getPublishers(entity?: number, obEntity?: boolean, onlyWithAllowList?: number): Observable<SelectItem[]> {
    const params: any = {};
    if (!TableService.isVoid(entity)) { params['entity'] = entity.toString(); }
    if (obEntity) { params['obEntity'] = 1; }
    if (onlyWithAllowList) { params['onlyWithAllowList'] = onlyWithAllowList; }
    return this.http.get<SelectItem[]>(this.getPublishersURL, {params: params});
  }

  getAllEntities(): Observable<EntityResponseDTO> {
    return this.http.get<EntityResponseDTO>(this.getAllEntititesURL);
  }

  getEntities(type?: number| number[], isBuyerSelf?: number): Observable<SelectItem[]> {
    const params: any = {};
    if (type) { params['type'] = type.toString(); }
    if (isBuyerSelf) { params['self'] = isBuyerSelf.toString(); }
    return this.http.get<SelectItem[]>(this.getEntityListURL, {params: params}).map( res => res.filter(elem => elem.label));
  }

  getUsersByEntity(entity: number, enabled: number): Observable<UserData[]> {
    const params: any = {};
    return this.http.get<UserData[]>(this.getUsersByEntityURL + '/' + entity + '/users/' + enabled);
  }
    // http://frontend-2.dev.platform.svc.fra.onetag/api/v1/publisher/XYZ/users

  getCurators(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getCuratorsURL);
  }

  getEntitiesOnType(type: number): Observable<SelectItem[]> {
    const params: any = {};
    if (type) { params['type'] = type.toString(); }
    return this.http.get<SelectItem[]>(this.getEntitiesOnTypeURL, {params: params});
  }

  getAdvertisers(entity?: number[], entityType?: number, userId?: number): Observable<SelectItem[]> {
    const params: any = {};
    if (userId) { params['userId'] = userId.toString(); }
    if (entity && entity.length > 0) { params['entity'] = entity.toString(); }
    if (entityType !== undefined) { params['entityType'] = entityType.toString(); }
    return this.http.get<SelectItem[]>(this.getAdvertisersURL, {params: params});
  }

  getSegments(entity?: number[], adver?: number[]): Observable<SelectItem[]> {
    const params: any = {};
    if (entity && entity.length > 0) { params['entity'] = entity.toString(); }
    if (adver && adver.length > 0) { params['advertiser'] = adver.toString(); }
    if (this.user && JwtPermissions.admin && this.user.id) { params['userId'] = this.user.id.toString(); }
    return this.http.get<SelectItem[]>(this.getSegmentsURL, {params: params});
  }

  getCampaigns(entity?: number[], adver?: number[]): Observable<SelectItem[]> {
    const params: any = {};
    if (entity && entity.length > 0) { params['entity'] = entity.toString(); }
    if (adver && adver.length > 0) { params['advertiser'] = adver.toString(); }
    return this.http.get<SelectItem[]>(this.getCampaignsURL, {params: params});
  }

  getCreativeGroups(entity?: number[], adver?: number[]): Observable<SelectItem[]> {
    const params: any = {};
    if (entity && entity.length > 0) { params['entity'] = entity.toString(); }
    if (adver && adver.length > 0) { params['advertiser'] = adver.toString(); }
    if (this.user && JwtPermissions.admin && this.user.id) { params['userId'] = this.user.id.toString(); }
    return this.http.get<SelectItem[]>(this.getCreativeGroupsURL, {params: params});
  }

  getAds(myCamp: number[]): Observable<SelectItem[]> {
    const params: any = {};
    if (myCamp && myCamp.length > 0) { params['campaign'] = myCamp.toString(); }
    return this.http.get<SelectItem[]>(this.getAdsURL, {params: params});
  }

  getStrategy(myCamp: number[]): Observable<SelectItem[]> {
    const params: any = {};
    if (myCamp && myCamp.length > 0) { params['campaign'] = myCamp.toString(); }
    return this.http.get<SelectItem[]>(this.getStrategyURL, {params: params});
  }

  getMedia(myAd: number[], cg: number[]): Observable<SelectItem[]> {
    const params: any = {};
    if (myAd && myAd.length > 0) { params['ad'] = myAd.toString(); }
    if (cg && cg.length > 0) { params['cg'] = cg.toString(); }
    return this.http.get<SelectItem[]>(this.getMediaURL, {params: params});
  }

  getBrands(term: string): Observable<SelectItem[]> {
    const params: any = {};
    if (term) { params['key'] = term; }
    return this.http.get<SelectItem[]>(this.getBrandsURL, {params: params});
  }

  getFormats(): Observable<Format[]> {
    return this.http.get<Format[]>(this.getFormatsURL);
  }

  getTemplates(isFloorprice?: boolean): Observable<SelectItem[]> {
    const params: any = {};
    if (isFloorprice) { params['floorprice'] = 1; }
    return this.http.get<SelectItem[]>(this.getTargetingTemplatesURL, {params: params});
  }

  getIntegrationMethod(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getIntegrationMethodURL);
  }

  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(this.getLanguagesURL);
  }

  getClusters(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getClusterUserURL);
  }

  getKeywordSourcesList(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getKeywordSourcesListURL);
  }

  getIspList(): Observable<IspProvider[]> {
    return this.http.get<IspProvider[]>(this.getIspListURL);
  }

  getDmpCategory(type?: string): Observable<SelectItem[]> {
    const params: any = {};
    if (type) { params['type'] = type; }
    return this.http.get<SelectItem[]>(this.getDmpCategoryURL, {params: params});
  }

  getInventoryCategories(): Observable<TreeNode> {
    return this.http.get<TreeNode>(this.getInventoryCategoriesURL);
  }

  getInventoryCategoriesList(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getInventoryCategoriesListURL);
  }

  getIabCategories(onlyFirstLevel: boolean = false): Observable<TreeNode> {
    const params: any = {};
    if (onlyFirstLevel) { params['onlyFirstLevel'] = 1; }
    return this.http.get<TreeNode>(this.getIabCategoriesURL, {params: params});
  }

  getCountries(isoCode?: boolean, marketId?: number): Observable<any[]> {
    const params: any = {};
    if (isoCode) { params['isoCode'] = '1'; }
    if (marketId !== undefined) { params['market'] = marketId; }
    return this.http.get<any[]>(this.getCountriesURL, {params: params});
  }

  getCountriesV2(isoCode?: boolean, marketId?: number): Observable<Country[]> {
    const params: any = {};
    if (isoCode) { params['isoCode'] = '1'; }
    if (marketId !== undefined) { params['market'] = marketId; }
    return this.http.get<Country[]>(this.getCountriesV2URL, {params: params});
  }

  getRegions(country: string): Observable<SelectItem[]> {
    const params: any = {};
    if (country) { params['country'] = country; }
    return this.http.get<SelectItem[]>(this.getRegionsURL, {params: params});
  }

  getCities(regions: number[]): Observable<SelectItem[]> {
    const params: any = {};
    if (regions) { params['region'] = regions.toString(); }
    return this.http.get<SelectItem[]>(this.getCitiesURL, {params: params});
  }

  stopReportCall(myReport: DataGenerateTable): Observable<any> {
    return this.http.post<SelectItem[]>(this.stopReportURL, myReport);
  }

  getReportEndpoint() {
    const queueFlagEnabled = this.appConfig.getFlag(AppConfigEnum.REPORTING_QUEUE_ENABLED);
    console.debug("Queue Flag ", queueFlagEnabled);
    return queueFlagEnabled? this.getReportURLV2 : this.getReportURL;
  }

  getReport(selected: DataGenerateTable, typeReport: number, isQueryString?: boolean): Observable<any> {
    const params: any = {};
    params['requireTotal'] = '1';

    switch (typeReport) {
      case Constants.REPORT_CAMPAIGN_ID: {
        params['type'] = 'campaign';
        break;
      }
      case Constants.REPORT_REVENUE_ID: {
        params['type'] = 'revenue';
        break;
      }
      case Constants.REPORT_SEGMENT_ID: {
        params['type'] = 'segment';
        break;
      }
      case Constants.REPORT_PAGE_ID: {
        params['type'] = 'page';
        break;
      }
      case Constants.REPORT_OM: {
        params['type'] = 'open_market';
        break;
      }
      case Constants.REPORT_HEATMAP: {
        params['type'] = 'geo';
        break;
      }
      case Constants.REPORT_AUDIENCE_EXTENSION_ID: {
        params['type'] = 'audienceExtension';
        break;
      }
    }
    params['lang'] = InfoService.getLanguage(this.translate.currentLang).toString();

    if (selected.pod && selected.pod > 0) {
      // gestione della chiamata ai report su macchine di sviluppo (con selezionato il pod a cui fare la chiamata)
      return this.http.post(this.getReportTestURLV2, selected, {params: params}).retry(3);
    } else {
      // Caso solo stringa della query, altrimenti flusso standard
      if (isQueryString) {
        // se sto richiedendo solo la query faccio una chiamata direct
        params['isQueryString'] = '1';
        params['direct'] = '1';
        return this.http.post(this.getReportURL, selected, {params: params}).retry(3);
      } else {
        return this.http.post(this.getReportEndpoint(), selected, {params: params}).retry(3);
      }
    }
  }

  // metodo che serve per fare polling sul risultato del report elaborato
  getReportResponse(uid: number, queryReport?: number): Observable<ReportResponse> {
    let url = this.getReportResponseURL + uid;
    if (queryReport && queryReport === RepoConstants.QUERY_REPORT_ATHENA) {
      url += "?athenaReport="+ queryReport;
    }
    return this.http.get<ReportResponse>(url);
  }

  getUser(): Observable<UserData> {
    return this.http.get<UserData>(this.getUserURL);
  }

  saveReport(name: string, type: number, idReport?: number): Observable<any> {
    const selected  = this.extractReportQuery();
    const query: {name: string, date: string, json: string, id?: number} = {
      name: name,
      date: (new Date()).toDateString(),
      json: JSON.stringify(selected)
    };
    if (idReport) { query.id = idReport; }
    const params: any = {};
    if (type !== undefined) { params['type'] = type.toString(); }
    return this.http.post(this.getSaveReportURL, query, {params: params});
  }

  savePreset(name: string, type: number, groupType?: number, idReport?: number, idAdmin?: number): Observable<any> {
    const selected  = this.extractReportQuery();
    const query: {name: string, date: string, json: string, group: any, admin: number, id?: any} = {
      name: name,
      date: (new Date()).toDateString(),
      json: JSON.stringify(selected),
      group: groupType,
      id: idReport,
      admin: idAdmin
    };

    const params: any = {};
    if (type !== undefined) { params['type'] = type.toString(); }
    params['preset'] = '1';
    return this.http.post(this.getSaveReportURL, query, {params: params});
  }


  editAutoreport(name: string, type: number, idReport?: number): Observable<any> {
    const selected  = this.extractReportQuery();
    const query: {name: string, date: string, json: string, id?: any} = {
      name: name,
      date: (new Date()).toDateString(),
      json: JSON.stringify(selected),
      id: idReport,
    };

    const params: any = {};
    if (type !== undefined) { params['type'] = type.toString(); }
    return this.http.post(this.getSaveReportURL, query, {params: params});
  }

  getGlobalTargetings(entityId?: number): Observable<TargetingGroup[]> {
    const params: any = {};
    if (!TableService.isVoid(entityId)) { params['entity'] = entityId; }
    return this.http.get<TargetingGroup[]>(this.getGlobalTargetingsURL, {params: params});
  }

  getPublisherTargetings(entityId?: number): Observable<TargetingGroup[]> {
    const params: any = {};
    if (!TableService.isVoid(entityId)) { params['entity'] = entityId; }
    return this.http.get<TargetingGroup[]>(this.getPublisherTargetingsURL, {params: params});
  }

  addGlobalTargetings(targetingGroups: TargetingGroup[], entityId?: number): Observable<GlobalTargeting> {
    const params: any = {};
    if (!TableService.isVoid(entityId)) { params['entity'] = entityId; }
    return this.http.post<GlobalTargeting>(this.addGlobalTargetingsURL, {targetingGroups: targetingGroups}, {params: params});
  }

  addPublisherTargetings(targetingGroups: TargetingGroup[], entityId?: number): Observable<GlobalTargeting> {
    const params: any = {};
    if (!TableService.isVoid(entityId)) { params['entity'] = entityId; }
    return this.http.post<GlobalTargeting>(this.addPublisherTargetingsURL, {targetingGroups: targetingGroups}, {params: params});
  }

  updateDomainCampaign(campId: number, domain: string, excluded: number): Observable<any> {
    return this.http.post(this.updateDomainCampaignURL, {idCampaign: campId, domain: domain, excluded: excluded});
  }

// ------------------------------------------------------ CreativeList (Media) ----------------------------------------------

  getCreatives(idAdvertiser?: number, approve?: boolean): Observable<MediaGallery[]> {
    const params: any = {};
    if (idAdvertiser !== undefined) { params['idAdvertiser'] = idAdvertiser.toString(); }
    if (approve) { params['approve'] = '1'; }
    return this.http.get<MediaGallery[]>(this.getCreativesURL, {params: params});
  }

  addCreative(media: Media): Observable<Media> {
    return this.http.post<Media>(this.addCreativeURL, media);
  }

  modifyCreative(media: Media, campaignId?: number): Observable<Media> {
    const params: any = {};
    if (campaignId) { params['IdCampaign'] = campaignId.toString(); }
    return this.http.post<Media>(this.modifyCreativeURL, media, {params: params}).catch(this.handleError);
  }

  deleteCreative(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.post(this.deleteCreativeURL, '', {params: params}).catch(this.handleError);
  }

  disjoinCreative(id: number, campaign: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    if (campaign !== undefined) { params['campaign'] = campaign.toString(); }
    return this.http.post(this.disjoinCreativeURL, '', {params: params}).catch(this.handleError);
  }

  joinCreatives(idCampaign: number, creativesList: number[]): Observable<any> {
    return this.http.post(this.joinCreativesURL, {idCampaign: idCampaign, creativesList: creativesList}).catch(this.handleError);
  }


  getSavedReports(id?: number, idUser?: number): Observable<ReportSaved[]> {  // Prende la lista dei report salvati
    const params: any = {};
    if (id !== undefined && id !== null) { params['type'] = id.toString(); }
    if (idUser !== undefined) { params['idUser'] = idUser.toString(); }
    return this.http.get<ReportSaved[]>(this.getSavedReportsURL, {params: params});
  }

  getPresetsByGroup(idGroup: number): Observable<Preset[]> {  // Prende la lista dei preset salvati
    const params: any = {};
    params['preset'] = '1';
    if (idGroup !== undefined) { params['idGroup'] = idGroup.toString(); }
    return this.http.get<Preset[]>(this.getSavedReportsURL, {params: params});
  }

  getInfoReportSaved(id: number, type: number, onlyReturn?: boolean): Observable<DataGenerateTable> { // Prende le informazioni sul report salvato
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.get<DataGenerateTable>(this.getSavedReportURL, {params: params}).map(res => {
      if (!onlyReturn) {
        this.populateField(res, type);
      }
      return res;
    });
  }

  getInfoPreset(id: number, setDataOnInfoService?: boolean, type?: number): Observable<DataGenerateTable> { // Prende le informazioni sul report salvato
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    params['preset'] = '1';
    return this.http.get<DataGenerateTable>(this.getSavedReportURL, {params: params}).map(res => {
      if (setDataOnInfoService) {
        this.populateField(res, type);
      }
      return res;
    });
  }

  reportLinkGeneration(report: ReportSaved): Observable<any> {
    return this.http.post(this.reportLinkGenerationURL, report, {responseType: 'text'});
  }

  reportLinkDelete(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.post(this.reportLinkDeleteURL, '', {params: params});
  }

  handleAutoReport(report: ReportSaved): Observable<number> {
    return this.http.post<number>(this.startAutoReportURL, report);
  }

  stopAutoReport(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.post(this.stopAutoReportURL, '', {params: params});
  }

  deleteSavedReport(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.post(this.deleteAutoReportURL, '', {params: params});
  }

  deletePreset(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    params['preset'] = '1';
    return this.http.post(this.deleteAutoReportURL, '', {params: params});
  }

  deleteUser( id: string ): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id; }
    return this.http.post(this.deleteUserURL, '', {params: params}).catch(this.handleError);
  }

  changeConfigUser(id: number, json: string, columnListRenamed: Column[]): Observable<any> {
    const params: any = {};
    if (this.user.language !== undefined) { params['lang'] = this.user.language.toString(); }
    return this.http.post(this.changeConfigUserURL, {id: id, json: json, columnListRenamed: columnListRenamed}, {params: params}).catch(this.handleError);
  }

  changeConfigGroup(id: number, json: string, columnListRenamed: Column[]): Observable<any> {
    const params: any = {};
    if (this.user.language !== undefined) { params['lang'] = this.user.language.toString(); }
    return this.http.post(this.changeConfigGroupURL, {id: id, json: json, columnListRenamed: columnListRenamed}, {params: params}).catch(this.handleError);
  }

  changeEmail(newMail: string, idAlias?: number): Observable<any> {
    return this.http.post(this.changeEmailURL, idAlias ? { email: newMail, idAlias: idAlias } : { email: newMail }).catch(this.handleError);
  }

  changeGroupUser(idUser: number, idGroup: number): Observable<any> {
    return this.http.post(this.changeGroupUserURL, {idUser: idUser, idGroup: idGroup});
  }

  saveNewGroup(id: number, name: string, idEntityType: number): Observable<any> {
    return this.http.post(this.createGroupURL, {id: id, name: name, type: idEntityType}).catch(this.handleError);
  }

  saveAdminDefaultColumns(adminCol: ColumnWithType): Observable<any> {
    return this.http.post(this.saveAdminDefaultColumnsURL, adminCol).catch(this.handleError);
  }

  getBookableEvents(): Observable<GlobalEvent[]> {
    return this.http.get<GlobalEvent[]>(this.getBookableEventsURL);
  }

  // Ritorna la lista delle campagne
  getCampaignsList(id: number, lang: number, type?: number): Observable<TreeNode[]> {
    const params: any = {};
    if (id !== undefined) { params['status'] = id.toString(); }
    if (lang !== undefined) { params['lang'] = lang.toString(); }
    if (type !== undefined) { params['type'] = type.toString(); }
    return this.http.get<{data: TreeNode[]}>(this.getCampaignsListURL, {params: params}).map(res => res.data);
  }

  // aggiorna la lista delle campagne modificate
  setCampaignStatus(id: number, type: string, command: number, programmatic?: number): Observable<any> {
    const el: any = {id: id, type: type, command: command};
    if (programmatic) {el.programmatic = programmatic; }
    return this.http.post(this.setCampaignStatusURL, el).catch(this.handleError);
  }

  deleteCampaignElement(id: number, type: string): Observable<any> {
    return this.http.post(this.setCampaignStatusURL, {id: id, type: type, deleted: 1}).catch(this.handleError);
  }

  getCampaignDetails(param: any): Observable<Campaign> {
    const params: any = {};
    if (param !== undefined) { params['id'] = param.toString(); }
    return this.http.get<Campaign>(this.getCampaignDetailURL, {params: params});
  }

  getContract(userId?: number, entityId?: number): Observable<{ map: { contract: string } }> {
    const params: any = {};
    if (userId !== undefined) { params['userId'] = userId.toString(); }
    if (entityId !== undefined) { params['entity'] = entityId.toString(); }
    return this.http.get<{map: {contract: string}}>(this.getContractURL, {params: params});
  }

  getTerms(userId?: number, entityId?: number): Observable<SelectItem> {
    const params: any = {};
    if (userId !== undefined) { params['userId'] = userId.toString(); }
    if (entityId !== undefined) { params['entity'] = entityId.toString(); }
    return this.http.get<SelectItem>(this.getTermsURL, {params: params});
  }

  getNotifications(): Observable<NotificationData[]> {
    return this.http.get<NotificationData[]>(this.getNotificationsURL);
  }

  getInviteNotifications(): Observable<number> {
    return this.http.get<number>(this.getInviteNotificationsURL);
  }

  getCampaignsBlacklist(): Observable<BLCampaignEntryData[]> {
    return this.http.get<BLCampaignEntryData[]>(this.getCampaignsBlacklistURL);
  }

  restoreCampaignNotification(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['campaign'] = id.toString(); }
    return this.http.post(this.restoreCampaignNotificationURL, null, {params: params});
  }

  setReadNotifications(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id_last_notification'] = id.toString(); }
    return this.http.post(this.setReadNotificationsURL, null, {params: params});
  }

  addCampaignToBlacklist(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['campaign'] = id.toString(); }
    return this.http.post(this.addCampaignToBlacklistURL, null, {params: params});
  }

  /**
   * @param type: 1 per flight; 2 per targeting
   */
  getAlgorithms(type?: number): Observable<Algorithm[]> {
    const params: any = {};
    if (type !== undefined) { params['type'] = type.toString(); }
    return this.http.get<Algorithm[]>(this.getAlgorithmsURL, {params: params});
  }

  getAgeRanges(type?: number): Observable<SelectItem[]> {
    const params: any = {};
    if (type !== undefined) { params['type'] = type.toString(); }
    return this.http.get<SelectItem[]>(this.getAgeRangesURL, {params: params});
  }

  getOldStrategies(idAdvertiser: number): Observable<{ value: any, label: any, channel: number }[]> {
    const params: any = {};
    if (idAdvertiser !== undefined) { params['idAdvertiser'] = idAdvertiser.toString(); }
    return this.http.get<{value: any, label: any, channel: number}[]>(this.getOldStrategyURL, {params: params}).catch(this.handleError);
  }

  getIssue(entityId?: number): Observable<PublisherIssue> {
    const params: any = {};
    if (!TableService.isVoid(entityId)) { params['entity'] = entityId; }
    return this.http.get<PublisherIssue>(this.issueURL, {params: params});
  }

  getTimezonesUnAuth(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getTimezonesUnAuthURL);
  }

  sendApi() {
    return this.http.get<any>(GlobalSettings.APIurl + '/api/v1/deal/notification');
  }

  validateApps(list: string[]) { // : Observable<[]>
    return this.http.post<any>(this.validateAppsURL, list);
  }

  validateCap(list: string[]) { // : Observable<[]>
    return this.http.post<any>(this.validateCapURL, list);
  }

  getGamingView(entityID: number, advertiserID: number): Observable<boolean> {
    return this.http.get<boolean>(this.gamingViewURL + '/' + entityID + '/' + (advertiserID ? advertiserID : 0));
  }

  getBrowsers(): Observable<any> {
    return this.http.get<any>(this.browsersURL);
  }

  getOs(): Observable<any> {
    return this.http.get<any>(this.osURL);
  }

  getDevices(): Observable<any> {
    return this.http.get<any>(this.devicesURL);
  }

  changeUserOp(res: ResponseInfo) {
    this.changingUser.next(res)
  }

  getQuicksightUrl(): Observable <any> {
    return this.http.get<any>(GlobalSettings.APIV2url + '/v2/quicksight/embed');
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  async getIpAddress() {
    return await this.http.get("https://ipapi.co/json/").toPromise();
    // return await this.http.get('http://ipv4.myexternalip.com/json').toPromise();
    // return await this.http.get('https://geolocation-db.com/json/').toPromise();
    // return await this.http.get("http://api.ipify.org/?format=json").toPromise();
  }

  getAllAuthorities(): Observable<any[]> {
    return this.http.get<any[]>(this.getAllAuthoritiesURL);
  }

  updateGroupAuthorities(entityId: number, dto: any): Observable<any> {
    return this.http.put(`${this.updateGroupAuthoritiesURL}/${entityId}/authorities`, dto)
  }

  getGroupAuthorities(entityId: number): Observable<any> {
    return this.http.get(`${this.getGroupAuthoritiesURL}/${entityId}/authorities`);
  }

  updateUserAuthorities(entityId: number, dto: any): Observable<any> {
    return this.http.put(`${this.updateUserAuthoritiesURL}/${entityId}/authorities`, dto);
  }

  getUserAuthorities(entityId: number): Observable<any> {
    return this.http.get(`${this.getUserAuthoritiesURL}/${entityId}/authorities`);
  }
}
